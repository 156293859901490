.notification {
	position: fixed;
	top: 10px;
	right: 10px;
	display: none;
	border: 1px solid transparent;
	border-radius: 4px;
	margin-bottom: 1px;
	padding: 15px;
	width: 400px;
	max-width: calc(100% - 20px);
	z-index: 1090;
	float: right;
	font-size: 16px;
	transition: top .5s, opacity .1s;
	opacity: 0;

	&--success {
		color: #3c763d;
		background-color: #dff0d8;
		border-color: #d6e9c6; }

	&--info {
		color: #31708f;
		background-color: #d9edf7;
		border-color: #bce8f1; }

	&--warning {
		color: #8a6d3b;
		background-color: #fcf8e3;
		border-color: #faebcc; }

	&--danger,
	&--error {
		color: #a94442;
		background-color: #f2dede;
		border-color: #ebccd1; } }
