.has--alert {
	overflow: hidden; }

.alert {
	&_container {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1050;
		visibility: hidden;
		z-index: -1;

		.is--transitioning & {
			pointer-events: none; }

		.has--alert &,
		.is--transitioning & {
			z-index: 1050;
			visibility: visible; } }

	&_overlay {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0; }

	&_wrapper {
		position: relative;
		z-index: 2;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		padding: 1em;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0);
		transition: background .4s;

		.has--alert & {
			background: rgba(0, 0, 0, .6); } }

	&_content {
		background: #fff;
		max-width: 600px;
		max-height: 600px;
		width: 100%;
		border-radius: 4px;
		transition: transform .4s;
		transform: translateY(-20vmin);
		position: relative;
		z-index: 2;

		.has--alert & {
			transform: translateY(0); } }

	&_item {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		max-height: inherit; }

	&_close {
		position: absolute;
		right: 0;
		top: 0; }

	&_header,
	&_body {
		padding: 1em 2em; }

	&_header {
		border-bottom: 1px solid #eee; }

	&_body {
		flex-grow: 1;
		overflow: auto; }

	&_title {
		color: purple;
		font-size: 30px;
		text-align: left; } }



