body {
	margin: 0;
	background: #fff; }

img {
	max-width: 100%; }

body a {
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-o-transition: 0.5s all;
	-ms-transition: 0.5s all;
	text-decoration: none; }

h1,h2,h3,h4,h5,h6 {
	margin: 0; }

p {
	margin: 0;
	font-family: 'Lato', sans-serif; }

ul,label {
	margin: 0;
	padding: 0; }

body a:hover {
	text-decoration: none; }

.header {
	padding: 1.5em 0;
	background: rgba(156, 157, 158, 0.38);
	position: absolute;
	z-index: 999;
	width: 100%; }

.lk-logo h2 {
	font-size: 4em;
	color: #fff;
	letter-spacing: 2px;
	font-weight: 900;
	line-height: 0.8em; }

.lk-logo h2 a {
	color: #fff;
	display: block;
	text-decoration: none; }

.lk-header-right {
	margin: 1em 0 0; }

.lk-header-right ul li {
	display: inline-block;
	color: #fff;
	font-size: 1em;
	margin-right: 1.5em;
	font-family: 'Text Me One', sans-serif;
	letter-spacing: 1px; }

.lk-header-right ul li a {
	color: #fff;
	-webkit-transition: .5s all;
	-moz-transition: .5s all;
	transition: .5s all; }

.lk-header-right ul li a:hover {
	color: #000; }

.lk-header-right i.glyphicon {
	font-size: 1.1em;
	margin-right: .5em; }

.logo h1 {
	font-size: 3em;
	font-weight: 900; }

.logo h1 a,.logo h1 a:hover {
	display: inline-block;
	color: #fff;
	text-decoration: none;
	font-family: 'Raleway', sans-serif; }

.lk-logo h2 a {
	color: #FFFFFF;
	display: block;
	text-decoration: none;
	font-family: 'Raleway', sans-serif; }

.menu-wrap a {
	color: #fff; }

.icon-list li a {
	text-align: left; }

.menu-wrap a:hover,
.menu-wrap a:focus,.menu-wrap a.active {
	color: #000;
	text-decoration: none; }

.content-wrap {
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch; }

.content {
	position: relative;
	background: #b4bad2; }

.content::before {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
	content: '';
	opacity: 0;
	-webkit-transform: translate3d(100%,0,0);
	-moz-transform: translate3d(100%,0,0);
	-o-transform: translate3d(100%,0,0);
	-ms-transform: translate3d(100%,0,0);
	transform: translate3d(100%,0,0);
	-webkit-transition: opacity 0.4s, -webkit-transform 0s 0.4s;
	-moz-transition: opacity 0.4s, transform 0s 0.4s;
	transition: opacity 0.4s, transform 0s 0.4s;
	-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	-moz-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1); }

.menu-button {
	position: fixed;
	z-index: 1000;
	padding: 0;
	width: 2.5em;
	height: 2em;
	border: none;
	font-size: 1.5em;
	color: transparent;
	background: rgba(60, 50, 50, 0.22);
	margin: 0em 0 0 8em;
	outline: none; }

.menu-button::before {
	position: absolute;
	top: 10px;
	right: 10px;
	bottom: 10px;
	left: 10px;
	background: -webkit-linear-gradient(#fff 20%, transparent 20%, transparent 40%, #fff 40%, #fff 60%, transparent 60%, transparent 80%, #fff 80%);
	background: linear-gradient(#fff 20%, transparent 20%, transparent 40%, #fff 40%, #fff 60%, transparent 60%, transparent 80%, #fff 80%);
	content: ''; }

.menu-button:hover {
	-moz-opacity: 0.8;
	opacity: 0.8; }

.close-button {
	width: 1em;
	height: 1em;
	position: absolute;
	right: 1em;
	top: 1em;
	overflow: hidden;
	text-indent: 1em;
	font-size: 1em;
	border: none;
	background: transparent;
	color: transparent;
	outline: none; }

.close-button::before, .close-button::after {
	content: '';
	position: absolute;
	width: 3px;
	height: 100%;
	top: 0;
	left: 38%;
	background: #fff; }

.close-button::before {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg); }

.close-button::after {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg); }

.icon-list li {
	display: block; }

.icon-list {
	margin-top: 2em; }

.menu-wrap {
	position: fixed;
	z-index: 1001;
	width: 300px;
	height: 100%;
	background: rgb(28, 199, 161);
	padding: 4em 2em 0;
	font-size: 1.3em;
	font-family: 'Roboto Condensed', sans-serif;
	-webkit-transform: translate3d(-320px,0,0);
	-moz-transform: translate3d(-320px,0,0);
	-o-transform: translate3d(-320px,0,0);
	-ms-transform: translate3d(-320px,0,0);
	transform: translate3d(-320px,0,0);
	-webkit-transition: -webkit-transform 0.4s;
	-moz-transition: transform 0.4s;
	transition: transform 0.4s;
	-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	-moz-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	left: 0;
	top: 0;
	text-align: center; }

.menu,
.icon-list {
	height: 100%; }

.icon-list {
	display: inline-block; }

.icon-list a i.glyphicon {
	margin-right: 0.6em;
	vertical-align: text-top; }

.icon-list {
	-webkit-transform: translate3d(0,100%,0);
	-moz-transform: translate3d(0,100%,0);
	-o-transform: translate3d(0,100%,0);
	-ms-transform: translate3d(0,100%,0);
	transform: translate3d(0,100%,0); }

.icon-list a {
	display: block;
	padding: 0.8em; }

.icon-list,
.icon-list a {
	-webkit-transition: -webkit-transform 0s 0.4s;
	-moz-transition: transform 0s 0.4s;
	transition: transform 0s 0.4s;
	-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	-moz-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1); }

.icon-list a:nth-child(2) {
	-webkit-transform: translate3d(0,1000px,0);
	-moz-transform: translate3d(0,1000px,0);
	-o-transform: translate3d(0,1000px,0);
	-moz-transform: translate3d(0,1000px,0);
	transform: translate3d(0,1000px,0); }

.icon-list a:nth-child(3) {
	-webkit-transform: translate3d(0,1500px,0);
	-moz-transform: translate3d(0,1500px,0);
	-o-transform: translate3d(0,1500px,0);
	-ms-transform: translate3d(0,1500px,0);
	transform: translate3d(0,1500px,0); }

.icon-list a:nth-child(4) {
	-webkit-transform: translate3d(0,2000px,0);
	-moz-transform: translate3d(0,2000px,0);
	-o-transform: translate3d(0,2000px,0);
	-ms-transform: translate3d(0,2000px,0);
	transform: translate3d(0,2000px,0); }

.icon-list a:nth-child(5) {
	-webkit-transform: translate3d(0,2500px,0);
	-moz-transform: translate3d(0,2500px,0);
	-o-transform: translate3d(0,2500px,0);
	-ms-transform: translate3d(0,2500px,0);
	transform: translate3d(0,2500px,0); }

.icon-list a:nth-child(6) {
	-webkit-transform: translate3d(0,3000px,0);
	-moz-transform: translate3d(0,3000px,0);
	-o-transform: translate3d(0,3000px,0);
	-ms-transform: translate3d(0,3000px,0);
	transform: translate3d(0,3000px,0); }

.icon-list a span {
	margin-left: 10px;
	font-weight: 700; }

.show-menu .menu-wrap {
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-o-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	-webkit-transition: -webkit-transform 0.8s;
	-moz-transition: transform 0.8s;
	transition: transform 0.8s;
	-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	-moz-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1); }

.show-menu .icon-list,
.show-menu .icon-list a {
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-o-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
	-webkit-transition: -webkit-transform 0.8s;
	-moz-transition: transform 0.8s;
	transition: transform 0.8s; }

.show-menu .icon-list a {
	-webkit-transition-duration: 0.9s;
	transition-duration: 0.9s;
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
	-moz-transition: 0.5s all;
	-o-transition: 0.5s all;
	-ms-transition: 0.5s all; }

.show-menu .content::before {
	opacity: 1;
	-webkit-transition: opacity 0.8s;
	-moz-transition: opacity 0.8s;
	transition: opacity 0.8s;
	-webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	-moz-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	transition-timing-function: cubic-bezier(0.7,0,0.3,1);
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	-o-transform: translate3d(0,0,0);
	-ms-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0); }

.slider {
	position: relative; }

.rslides {
	position: relative;
	list-style: none;
	overflow: hidden;
	width: 100%;
	padding: 0;
	margin: 0; }

.rslides li {
	-webkit-backface-visibility: hidden;
	width: 100%;
	left: 0;
	top: 0; }

.rslides img {
	display: block;
	height: auto;
	float: left;
	width: 100%;
	border: 0; }

.caption {
	width: 60%;
	position: absolute;
	top: 44%;
	left: 20%;
	text-align: center; }

.caption h3 {
	color: #ffffff;
	font-size: 3.0em;
	text-transform: uppercase;
	font-family: 'Raleway', sans-serif;
	letter-spacing: 3px; }

.caption p {
	line-height: 1.8em;
	font-size: 1em;
	text-align: center;
	font-weight: 400;
	width: 81%;
	margin: 22px auto 0px;
	color: #eee;
	letter-spacing: 2px; }

.callbacks_tabs a:after {
	content: "\f111";
	font-size: 0;
	font-family: FontAwesome;
	visibility: visible;
	display: block;
	height: 15px;
	width: 15px;
	display: inline-block;
	border: 2px solid #FFFFFF;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	background: #506a85; }

.callbacks_here a:after {
	background: rgb(23, 167, 139); }

.callbacks_tabs a {
	visibility: hidden; }

.callbacks_tabs li {
	display: inline-block;
	display: none; }

ul.callbacks_tabs.callbacks1_tabs {
	position: absolute;
	bottom: -2px;
	z-index: 999;
	left: 46%;
	background: #fff; }

.callbacks_nav {
	position: absolute;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	top: 44%;
	left: 14%;
	opacity: 0.7;
	z-index: 3;
	text-indent: -9999px;
	overflow: hidden;
	text-decoration: none;
	height: 61px;
	width: 38px;
	background: none;
	margin-top: -45px; }

.callbacks_nav.next {
	left: auto;
	background-position: right top;
	right: 14%; }

.serach-w3agile {
	background: #02B875;
	padding: 3em 0; }

.about,.services,.contact {
	padding: 4em 0; }

.lk-about-grids {
	padding: 4em 0; }

div#about {
	background-color: #fff; }

.lk-about-left h2 {
	color: #25c1ec;
	font-family: 'Raleway', sans-serif; }

.about-agileits-title h3,.agile-heading h3,.agileits-title h3, h3,.services h3,.contact-agileits-title h3 {
	font-size: 3.5em;
	color: #fa3d43;
	text-decoration: none;
	font-weight: 300;
	padding: 0;
	font-family: 'Roboto Condensed', sans-serif;
	margin: 0;
	text-align: center; }

.lk-about-left h5 {
	font-size: .9em;
	color: #212121;
	text-decoration: none;
	margin: 1em 0;
	text-transform: uppercase;
	font-weight: 600;
	line-height: 1.8em; }

.lk-about-left p {
	color: #212121;
	font-size: .9em;
	margin: 0;
	line-height: 1.8em;
	letter-spacing: 1px; }

.lk-about-left p span {
	margin: 1em 0 0 0;
	display: block; }

.lk-about-img img {
	width: 100%; }

.services {
	background: #2d2d2d;
	text-align: center; }

.ser-top {
	margin-bottom: 5em; }

span.fa.fa-calculator,span.fa.fa-bank,span.fa.fa-archive,span.fa.fa-money,span.fa.fa-user,span.fa.fa-cogs,span.fa.fa-database {
	font-size: 3em;
	color: #fff; }

.icon1 span.fa.fa-calculator {
	color: #fff; }

.icon2 span.fa.fa-bank {
	color: #3cb9a9; }

.icon3 span.fa.fa-archive {
	color: #3cb9a9; }

.icon4 span.fa.fa-money {
	color: #3cb9a9; }

.icon5 span.fa.fa-user {
	color: #3cb9a9; }

.icon6  span.fa.fa-cogs {
	color: #3cb9a9; }

.icon7 span.fa.fa-database {
	color: #3cb9a9; }

span.glyphicon.glyphicon-time {
	color: #3cb9a9; }

.ser-left {
	text-align: center;
	margin-bottom: 2em; }

.services h4 {
	font-size: 1.4em;
	margin: 1em 0 0;
	color: #fff; }

.services p {
	font-size: 1em;
	line-height: 1.8em;
	color: rgba(255, 255, 255, 0.59);
	margin: 1em 0; }

.ser-top p {
	width: 60%;
	margin: 2em auto; }

.ser-btm {
	margin-top: 3em; }

div#contact {
	padding: 4em 0; }

.get-touch {
	padding: 5em 0em; }

.get-touch-top {
	text-align: center;
	padding-bottom: 3em; }

.get-touch-top h3 {
	font-size: 3em;
	color: #CF0C68;
	margin-bottom: 0.2em;
	font-family: 'Share-Regular'; }

.get-touch-top p {
	font-size: 1.05em;
	color: #716E6E;
	margin: 0 auto;
	width: 50%;
	line-height: 1.8em; }

.get-touch-bottom input[type="text"] {
	display: inline-block;
	background: none;
	border: 1px solid #A3A1A1;
	width: 49.2%;
	outline: none;
	font-size: 0.95em;
	color: #747577;
	font-weight: 400;
	border-radius: 2px;
	padding: 14px 15px;
	margin: 0% 1% 2% 0%; }

.get-touch-bottom input.no-mar {
	margin: 0% 0% !important; }

.get-touch-bottom textarea {
	background: none;
	border: 1px solid #A3A1A1;
	width: 100%;
	display: block;
	height: 15em;
	outline: none;
	font-size: 0.95em;
	color: #747577;
	font-weight: 400;
	resize: none;
	border-radius: 3px;
	padding: 10px 15px 10px 15px; }

.get-touch-bottom input[type="submit"] {
	font-size: 1em;
	color: #FFFFFF;
	padding: 0.6em 1em 0.6em 1em;
	display: block;
	border: none;
	margin: 1em auto 0em;
	outline: none;
	background: #fa3d43;
	border-radius: 3px;
	text-align: center;
	width: 14%; }

.get-touch-bottom input[type="submit"]:hover {
	background: #312f30;
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-o-transition: 0.5s all; }

.lk_footer {
	min-height: 100px;
	padding: 5em 0;
	position: relative;
	background-color: #252424; }

.lk_footer_grids p.agileits_w3layouts_est {
	color: #fff;
	line-height: 2em;
	margin: 2em auto 6em;
	width: 65%;
	text-align: center; }

.lk_footer_grid_leftl {
	float: left;
	width: 55px;
	height: 55px;
	border-radius: 50px;
	text-align: center;
	background: #fa3d43; }

.lk_footer_grid_leftl i {
	color: #fff;
	font-size: 1em;
	line-height: 3.8em; }

.lk_footer_grid_leftr {
	float: right;
	width: 80%; }

.lk_footer_grid_leftr h4 {
	font-size: 1.2em;
	color: #fff;
	letter-spacing: 3px;
	margin-bottom: .5em; }

.lk_footer_grid_leftr p {
	color: #999;
	line-height: 1.5em; }

.lk_footer_grid_leftr a {
	color: #999;
	text-decoration: none;
	font-size: 14px; }

.lk_footer_grid_leftr a:hover {
	color: #fa3d43; }

.lk_footer_pos {
	position: absolute;
	padding: 1em;
	left: 0%;
	bottom: 0%;
	width: 100%; }

.lk_footer_pos p {
	text-align: center;
	color: #fff;
	line-height: 1.8em;
	letter-spacing: 2px; }

.lk_footer_pos p a {
	color: #fa3d43;
	text-decoration: underline; }

.lk_footer_pos p a:hover {
	color: #fff; }

.lk_footer_grid_left:hover .lk_footer_grid_leftl {
	background: #fff;
	transition: 0.5s all;
	-webkit-transition: 0.5s all;
	-moz-transition: 0.5s all;
	-webkit-appearance: none; }

.lk_footer_grid_left:hover .lk_footer_grid_leftl i {
	color: #212121; }

.lk_footer_grid_left:hover .lk_footer_grid_leftr h4 {
	color: #fa3d43; }

.mapa {
	border: 0;
	pointer-events: none;
	height: 400px; }

#toTop {
	display: none;
	text-decoration: none;
	position: fixed;
	bottom: 20px;
	right: 2%;
	overflow: hidden;
	z-index: 999;
	width: 46px;
	height: 54px;
	border: none;
	text-indent: 100%;
	background: url(../images/arr.png) no-repeat 0px 0px; }

#toTopHover {
	width: 32px;
	height: 32px;
	display: block;
	overflow: hidden;
	float: right;
	opacity: 0;
	-moz-opacity: 0;
	filter: alpha(opacity=0); }

#toTop {
	display: none;
	text-decoration: none;
	position: fixed;
	bottom: 20px;
	right: 2%;
	overflow: hidden;
	z-index: 999;
	width: 46px;
	height: 54px;
	border: none;
	text-indent: 100%;
	background: url(../images/arr.png) no-repeat 0px 0px; }

#toTopHover {
	width: 32px;
	height: 32px;
	display: block;
	overflow: hidden;
	float: right;
	opacity: 0;
	-moz-opacity: 0;
	filter: alpha(opacity=0); }

@media(max-width:1080px) {
	.ser-top p {
		width: 74%; } }

@media(max-width:1024px) {
	.caption h3 {
		font-size: 3.2em; } }

@media(max-width:991px) {
	.caption h3 {
		font-size: 3.0em; }

	.lk-about-img {
		width: 55%;
		float: left; }

	.lk-about-left {
		width: 45%;
		float: left; }

	.lk_footer_grid_left {
		width: 33%;
		float: left; }

	.lk_footer_grid_leftr {
		float: right;
		width: 66%; } }

@media(max-width:800px) {
	.caption h3 {
		font-size: 2.4em; }

	.caption p {
		letter-spacing: 1px; }

	.header {
		padding: 1em 0; }

	.get-touch-bottom input[type="submit"] {
		width: 25%; } }

@media(max-width:768px) {
	.lk-logo h2 {
		font-size: 3em; }

	.caption h3 {
		letter-spacing: 2px; } }

@media(max-width:736px) {

	.rslides img {
		min-height: 34em; }

	.lk-logo {
		margin: 0;
		float: left;
		width: 40%; }

	.lk-header-right {
		margin: 1em 0 0;
		float: right;
		width: 60%;
		text-align: right; }

	.caption h3 {
		font-size: 2.3em; } }

@media(max-width:667px) {
	.lk-about-left {
		width: 100%;
		text-align: center; }

	.lk-about-img {
		width: 100%;
		padding-top: 4em; }

	.caption {
		top: 36%; }

	.lk-about-grids {
		padding: 2em 0; } }

@media(max-width:600px) {
	.lk_footer_grid_left {
		width: 100%;
		padding: 2em 0; }

	.lk_footer_grid_leftl {
		margin-left: 8em; }

	.lk-header-right ul li {
		margin-right: 1em; }

	.menu-button {
		margin: 0em 0 0 5em; }

	.menu-wrap {
		width: 242px; } }

@media(max-width:568px) {
	.menu-button {
		margin: 0em 0 0 5em; }

	.lk-logo {
		margin: 0;
		float: none;
		width: auto; }

	.lk-logo h2 {
		font-size: 3em;
		line-height: 0.9em;
		text-align: center; }

	.lk-header-right {
		margin: 1em 0 0;
		float: none;
		width: auto;
		text-align: center; }

	.menu-button {
		margin: 0em 0 0 0em; } }

@media(max-width:480px) {
	.lk_footer_grid_leftl {
		margin-left: 5em; }

	.lk-logo {
		margin: 0;
		float: none;
		width: auto; }

	.lk-logo h2 {
		font-size: 3em;
		line-height: 0.9em;
		text-align: center; }

	.lk-header-right {
		margin: 1em 0 0;
		float: none;
		width: auto;
		text-align: center; }

	.menu-button {
		margin: 0em 0 0 0em; }

	div#horizontalTab {
		padding: 2em 0; }

	.about-agileits-title h3, .services h3 {
		font-size: 3em; } }

@media(max-width:414px) {
	.lk_footer_grid_leftl {
		margin-left: 4em; }

	.caption {
		top: 30%;
		width: 100%; }
	.caption {
		top: 24%; }

	.lk-logo {
		margin: 0;
		float: none;
		width: auto; }

	.lk-logo h2 {
		font-size: 3em;
		line-height: 0.9em;
		text-align: center; }

	.lk-header-right {
		margin: 1em 0 0;
		float: none;
		width: auto;
		text-align: center; }

	.menu-button {
		margin: 0em 0 0 0em; }

	.caption h3 {
		font-size: 2em; }

	.services h4 {
		font-size: 1.2em; }

	.caption {
		width: 100;
		top: 44%;
		left: 0%;
		top: 41%; } }

@media(max-width:384px) {

	.ser-left.wow.fadeInDown.icon1 {
		width: 100%; }

	.ser-left.wow.fadeInDown.icon3 {
		width: 100%; }

	.ser-left.wow.fadeInDown.icon4 {
		width: 100%; }

	.ser-left.wow.fadeInDown.icon5 {
		width: 100%; }

	.ser-left.wow.fadeInDown.icon6 {
		width: 100%; }

	.ser-left.wow.fadeInDown.icon6 {
		width: 100%; }

	.menu-button {
		margin: 0em 0 0 4em; }

	.menu-button {
		margin: 0; } }

@media(max-width:375px) {
	.caption {
		top: 38%;
		width: 100%; } }

@media(max-width:320px) {
	.lk_footer_grid_leftl {
		margin-left: 2em; }

	.menu-button {
		width: 2em;
		height: 2em; }

	.caption h3 {
		font-size: 1.5em; }

	.menu-button {
		width: 2em;
		height: 2em; }

	.menu-button {
		margin: 0em; }

	.lk-logo h2 {
		font-size: 3em;
		line-height: 0.9em; }

	.lk-header-right ul li {
		letter-spacing: 0px; }

	.lk-logo {
		margin: 0;
		float: none;
		width: auto; }

	.lk-logo h2 {
		font-size: 3em;
		line-height: 0.9em;
		text-align: center; }

	.lk-header-right {
		margin: 1em 0 0;
		float: none;
		width: auto;
		text-align: center; }

	.rslides img {
		min-height: 28em; }

	.caption {
		top: 42%; }

	.caption p {
		font-size: 0.8em; } }
